.main-div {
  max-width: 780px;
  padding: 0 20px;
  margin: 0 auto;
}

input {
  outline: none;
}

.banner {
  margin-bottom: 3px;
  display: flex;
}

.banText {
  flex: 1;
}

.banText h1 {
  font-family: 'blacker_pro_displayheavy';
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: bolder;
  margin-top: 43px;
}

.banText ul li {
  font-size: 16px;
  color: #757575;
  font-weight: 600;
  margin-bottom: 10px;
}

.banImg {
  max-width: 238px;
  flex: 0 0 238px;
}

.banImg img {
  width: 100%;
  margin-top: 43px
}


.whiteSection {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #20afd4;
}

.cityform {
  position: relative;
  box-sizing: border-box;
  padding: 22px 25px;
  margin-left: auto;
  margin-right: auto;
  width: 73%;
}

/*City Form*/
.selectCityForm {
  max-width: 487px;
  margin: 0 auto;
}

.selectCityForm h2 {
  font-size: 25px;
  font-weight: 700;
  color: #00b1cc;
  margin: 0 0 5px;
}

.selectCityForm p {
  font-size: 16px;
  margin-bottom: 25px;
  font-weight: 600;
}

.planDetail {
  position: relative;
  padding: 9px 20px 9px 40px;
  margin-bottom: 20px;
  border: #eeeeee solid 3px;
  border-radius: 12px;
  box-shadow: 0 0 10px 2px #e7e7e7;
  display: flex;
  align-items: center;
}

.saveLabel {
  position: absolute;
  left: 25px;
  top: -15px;
  background-color: #ffdc02;
  width: 107px;
  border-radius: 5px;
  height: 26px;
  padding: 3px 5px 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.planDuration {
  max-width: 110px;
  flex: 0 0 110px;
  font-size: 30px;
  font-weight: 700;
  color: #1f1f1f;
}

.planPrice {
  flex: 1;
  text-align: right;
  font-size: 25px;
  line-height: 24px;
  font-weight: 700;
  color: #1f1f1f;
}

.planPrice em {
  font-size: 25px;
  font-style: normal;
  position: relative;
  top: -2px;
}

.planPrice span {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
}

.planPrice span em {
  font-size: 18px;
  font-style: normal;
  line-height: 18px;
  vertical-align: middle;
}

.planPrice .planPriceVal {
  margin-bottom: 1px;
}

.planPricenew .planPriceVal {
  margin-bottom: 1px;
  margin-top: 15px;
}

.planPrice .planPriceVal span {
  position: relative;
  top: -2px;
}

.cityFldBox {
  display: flex;
  align-items: flex-end;
}

.cityFl_lft {
  flex: 1;
  margin-right: 32px;
}

.cityFl_button {
  max-width: 143px;
  flex: 0 0 143px;
}

.cityFl_button .btn {
  width: 100%;
  /* margin-bottom: 40px;  */
  /* box-shadow: 0px 3px 10px #bbbbbb; */
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
}


/*City custom dropdown*/
.customSel_Dropdown {
  position: relative;
  z-index: 999;
}

.customSel_Dropdown.societyDrop {
  z-index: 99;
}

.cus_sel_dropBox {
  /* width: 100%;
    border: none;
    outline: none;
    border-radius: 12px; 
    display: block; 
    padding: 10px 20px; 
    background-color: #ffffff; 
    box-shadow: 0 0 10px 2px #e7e7e7; 
    color: #757575; 
    font-size: 13px; 
    font-weight: 600; 
    cursor: pointer; 
    position: relative; 
    min-height: 44px; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
}

/* .cus_sel_dropBox span.selectedVal{ display: block; width: calc(100% - 20px); text-overflow: ellipsis; white-space: nowrap; overflow: hidden;} */
/* .cus_sel_dropBox::after{content: ''; display: block; width: 16px; height: 16px; background-image: url("https://accounts.hindustantimes.com/images/printJourney3/dropdown-arrow.png"); background-repeat: no-repeat; background-position: center; position: absolute;  top: 15px; right: 12px;} */
/* .cus_sel_options{ display: none; padding: 0px 20px;  background-color: #ffffff; border: #757575 solid 1px; top:0px; left: 0; right: 0; position: absolute;  border-radius: 4px; } */
/* .cus_sel_options.opened{ display: block;}
.cus_sel_options::after{content: ''; display: block; width: 16px; height: 16px; background-image: url("https://accounts.hindustantimes.com/images/printJourney3/dropdown-arrow.png"); background-repeat: no-repeat; background-position: center; position: absolute;  top: 15px; right: 12px; transform: rotate(180deg);}
.cus_sel_options li{ list-style: none;  color: #757575; font-size: 16px; font-weight: 600; cursor: pointer; padding: 10px 0; border-bottom: #eeeee0 solid 1px; overflow: hidden; margin-right: 15px; word-break: break-all; }
.cus_sel_options li:last-child{ border: none;} */

.productImg {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.banText ul {
  margin-left: -12px;
}


.blackClourV2 {
  color: black;
}

.stepNavBar {
  margin: 0;
  max-width: 570px;
}

.stepNavBar ul {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-top: 70px;
}

.stepNavBar ul li {
  max-width: 90px;
  flex: 0 0 90px;
  list-style: none;
  position: relative;
}

.stepNavBar ul li::after {
  content: '';
  display: block;
  width: 150px;
  height: 4px;
  background-color: #b8b8b8;
  border-radius: 4px;
  position: absolute;
  top: 25px;
  left: 90px;
}

.stepNavBar ul li:last-child::after {
  display: none;
}

.stepNavBar ul li.active .stepItem span {
  background-color: #00b1cc;
  color: #ffffff;
}

.stepItem {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  position: relative;
}

.stepItem span {
  display: block;
  width: 49px;
  height: 49px;
  background-color: #eeeeee;
  color: #757575;
  border-radius: 100%;
  margin: 0 auto 6px;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  line-height: 49px;
}

.regBtns {
  margin-bottom: 12px;
}

.regBtns .btn {
  width: 146px;
}

.stepNavBar ul li.active .stepItem::before {
  content: '';
  display: block;
  width: 150px;
  height: 4px;
  background-color: #00b1cc;
  border-radius: 4px;
  position: absolute;
  top: 25px;
  right: 90px;
  z-index: 1;
}

.stepNavBar ul li.active .stepItem::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background-color: #00b1cc;
  border-radius: 4px;
  position: absolute;
  top: 25px;
  left: 90px;
  z-index: 1;
}

.stepNavBar ul li:first-child.active .stepItem::before {
  display: none;
}

.stepNavBar ul li:last-child.active .stepItem::after {
  display: none;
}

/* 24-AUg-2022- New Customer page ui changes  */
.selectCityForm p.cm-title {
  font-size: 16px;
  margin-bottom: 18px;
  margin-top: 12px;
  font-weight: 600;
  color: #000;
  margin-left: 10px;
}

.cm_planDetail {
  position: relative;
  padding: 8px 20px 8px 8px;
  margin-bottom: -15px;
  display: flex;
  align-items: center;
}

.cm_planDuration {
  max-width: 124px;
  /* flex: 0 0 110px; */
  font-size: 16px;
  font-weight: 600;
  color: #20afd4;
  margin-top: 10px;
}

.justify-center {
  justify-content: center;
}

.inline-flex {
  display: inline-flex;
}

.radio-group .radio:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

}

.radio-group .radio:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

}

.pd_10 {
  padding: 10px;
}

.radio-group .radio {
  position: relative;
}

.radio input {
  visibility: hidden;
  position: absolute;

}

.whiteSection1 {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
  padding-top: 22px;
}

.radio label:hover {
  cursor: pointer;
}

.radio input:checked+label {
  border-color: #20afd4 !important;
  background-color: #bff2ff !important;
}

.wd_150 {
  width: 105px;
  height: 99px;
  border-radius: 10px;
}

.banner_subtitle {
  font-size: 14px;
  color: #000;
  margin-left: 5px;
}

.ht_banner_title {
  margin-top: 54px;
}

.proceedBtn {
  font: normal normal 600 15px/30px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.proceedBtn:hover {
  font: normal normal 600 15px/30px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.banText ul {
  margin-left: -26px;
}

.cm-flex-type-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fnt-style {
  font: normal normal 900 11px / 15px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin-top: 5px;
}

.default-msg {
  font-size: 12px;
  color: #20afd4;
  text-align: center;
}

.plan_grid {
  position: relative;
  padding: 10px 10px 18px 10px;
  border: #eeeeee solid 3px;
  border-radius: 12px;
  box-shadow: 0 0 10px 2px #e7e7e7;
}

.whtsapp_style {
  font: normal normal normal 12px/45px Poppins;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
}

.whatsapp-main {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 32px;
}

#Whatsapp {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #AAAAAA;
  width: 16px;
  height: 16px;
}

.year {
  font-weight: 700;
  font-size: 24px;
  margin-top: -10px;
  color: black;
}

.saveLabel {
  position: absolute;
  left: 25px;
  top: -15px;
  background-color: #ffdc02;
  width: 107px;
  border-radius: 5px;
  height: 26px;
  padding: 3px 5px 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.saveLabel1 {
  position: absolute;
  left: 6px;
  top: -23px;
  background-color: #EF7B51;
  width: 107px;
  border-radius: 5px;
  height: 26px;
  padding: 3px 5px 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}

.planPriceDay {
  margin-top: -10px;
}

.selectCityForm1 {
  max-width: 470px;
  margin: 0 auto;
}

.planPricenew {
  flex: 1;
  text-align: right;
  font-size: 25px;
  line-height: 24px;
  font-weight: 700;
  color: #1f1f1f;
}

.planPricenew em {
  font-size: 21px;
  font-style: normal;
  position: relative;
  top: -2px;
}

.planPricenew span {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
}

.planPricenew span em {
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  vertical-align: middle;
  margin-left: 5px;
}

.fnt-style {
  font: normal normal 900 11px / 15px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin-top: 5px;
}

.selectcitytitle {
  color: black;
  margin-bottom: 22px;
  margin-left: 8px;
}

.pincodeError {
  color: red;
  font-weight: normal;
  font-size: 13px;
}

.ht_banner_title h1 {
  font-family: 'blacker_pro_displayheavy';
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bolder;
  color: #000;
}

.ht_banner_title ul {
  margin-left: -33px;
}

.ht_banner_title ul li {
  font-size: 16px;
  color: #757575;
  font-weight: 600;
  margin-bottom: 10px;
}

.ht_banner_img img {
  width: 100%;
}

/* END */
/* 30-Aug-2022 start */
.circle_style img {
  width: 60px;
}

.allTabs {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

input~.tab {
  display: none
}

#tab11:checked~.tab.content1,
#tab12:checked~.tab.content2,
#tab13:checked~.tab.content3,
#tab14:checked~.tab.content4,
#tab15:checked~.tab.content5 {
  display: block;
}

.ht_page {
  padding: 30px 30px 10px;
  border-radius: 10px;
}

.ht_tab {
  display: flex;
  margin-right: 10px;
}

.ht_city_selct input {
  display: none;
}

.ht_city_selct input~.tab {
  display: none
}

.ht_city_selct #tab1:checked~.tab.content1,
.ht_city_selct #tab2:checked~.tab.content2,
.ht_city_selct #tab3:checked~.tab.content3,
.ht_city_selct #tab4:checked~.tab.content4,
.ht_city_selct #tab5:checked~.tab.content5 {
  display: block;
}

.ht_city_selct input:checked+label {
  background: #DDF2F7 0% 0% no-repeat padding-box;
}

.ht_city_selct sinput~.tab {

  padding: 12px;
}

.fnt-style {
  font: normal normal 600 11px/15px Poppins;
  color: #000000;
  text-align: center;
  margin-top: 5px;
}

.fnt-style-city {
  margin-top: 11px;
  text-align: left;
  font: normal normal bold 28px/55px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.fnt-style-city1 {
  display: inline-block;
  margin-top: 4px;
  text-align: left;
  font: normal normal bold 28px/55px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.back-btn-pincode {
  display: flex;
  text-align: center;
  align-items: center;
  font: normal normal bold 28px/55px Poppins;
  letter-spacing: 0px;
  color: #000000;
  height: 39px;
}

.back-btn {
  display: inline-block;
}

.saveLabel_discount {
  display: flex;
  flex: 1;
  height: 100%;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 5px;
  background: #04A7D0 0% 0% no-repeat padding-box;
  /* border: 1px solid #CDCDCD; */
  border-radius: 8px 0px 0px 8px;
  font: normal normal normal 12px / 25px Poppins;
  color: #FFFFFF;
  /* height: -webkit-fill-available; */
}

.saveLabel_discount .percent {
  font: normal normal bold 15px / 25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
}

/* publication */
.ht_publication_tab {
  display: inline-block;
  padding: 0px 10px 20px 0px;
}

.ht_publication_selct input {
  display: none;
}

.ht_publication_selct {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px 10px 0px 30px;
}

.ht_publication_selct input~.tab {
  display: none
}

.ht_publication_selct #tab1:checked~.tab.content1,
.ht_publication_selct #tab2:checked~.tab.content2,
.ht_publication_selct #tab3:checked~.tab.content3,
.ht_publication_selct #tab4:checked~.tab.content4,
.ht_publication_selct #tab5:checked~.tab.content5 {
  display: block;
}

.ht_publication_selct input+label {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 0px;
}

.ht_publication_selct input:checked+label {
  background: #DBF0F5 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
}

.ht_publication_selct input~.tab {
  border-top: 1px solid #999;
  padding: 12px;
}

.pd_x_y {
  padding-right: 6px;
  padding-left: 6px;
}

.pricing-table {
  display: flex;
  flex: 2;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
}

.pricing-table.withDiscount {
  flex-direction: column !important;
}

.pricing-label-details {
  width: 100%;
  min-height: 110px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.pricing-label_img {
  text-align: center;
  position: absolute;
  top: -15px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #00000029;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  max-width: 90%;
}

/* .pricing-features .feature span {
  display: inline-block;
  float: right;
  color: #777575;
  font: normal normal bold 28px/55px Poppins;
  letter-spacing: 0px;
  vertical-align: middle;
} */
.pricing-features .feature {
  font: normal normal bold 28px/55px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.price-tag {
  font: normal normal bold 14px / 15px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.price-tag .stike {
  text-decoration: line-through;
  white-space: nowrap;
}

.title_more {
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  color: #0A94A9
}

.title_more span {
  margin-left: 10px;
}

.title_less {
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.header-shadow {
  -webkit-box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
}

.see_more_icon {
  margin-left: 7px;
  cursor: pointer;
}

/* 30-Aug-2022 end  */




/* <!-----Start CSS-13-09-2022-----New Journey-------------------------------------------> */
.carousel-inner img {
  width: 100%;
  height: 100%;
}

.NavBar nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 152px;
  margin-top: 30px;
}

#demo.carousel {
  margin-top: 30px;
}

#demo .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

#demo .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -60px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

#demo .carousel-indicators .active {
  opacity: 1;
  background: #04A7D0 0% 0% no-repeat padding-box !important;
  border: none;
}

.mt-70 {
  margin-top: 70px;
}

#demo .carousel-indicators li {
  background: #E4E4E4 0% 0% no-repeat padding-box;
  width: 9px;
  border-radius: 50%;
  height: 9px;
  border: none;
  opacity: unset;
}

.mt-100 {
  margin-top: 40px;
}

.popup_style-bg {
  border-radius: 10px;
  display: flex;
  padding: 14px 0px;
}

/* .navbar-brand img {
  margin-top: 10px;
} */

.bottom_contact p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  color: #000;
  text-align: center;
  padding: 7px 7px;
}

.bottom_contact p span {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  background: #04a7d0;
  padding: 10px 89px;
  border-radius: 6px;
  color: #fff;
}

.bottom_contact p span:hover {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  color: #fff;
  text-align: center;
  background: #04a7d0b6;
  padding: 10px 89px;
  border-radius: 6px;
}

.bottom_contact .footer-contact a {
  text-decoration: none !important;
  font-weight: 600;
  color: #000;
}

.bottom_contact .footer-contact a p {
  text-align: center;
}

.contact_box_design {
  color: #000;
}

#contact_box .modal-dialog {
  max-width: 413px !important;
}

.bottom_contact p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 1px;
  color: #000;
  text-align: center;
}

#contact_box .modal-body .close {
  position: absolute;
  top: -7px;
  right: -4px;
  background: #000;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 34px;
  z-index: 999999999;
}

.mt--8 {
  margin-top: -8px;
}

/* .ht_plan_summery {
  border-radius: 10px;
  border: 1px solid #056f8a;  
  background:#04A7D0;	
} */

.ht_plan_summery .header_title {
  background: #04a7d0;
  color: #fff;
  padding: 10px;
  padding: 10px 10px 5px 10px;
  border-radius: 10px 10px 0 0;
}

.plan_header_box .nws_paper_title .feature span.p_title {
  font: normal normal 900 28px/39px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.plan_header_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.add_detail_btn {
  background-color: #04a7d0;
  border: none;
  color: #fff;
  padding: 10px 25px;
  border-radius: 25px;
  font-size: 14px;
  margin-top: 10px;
}

.add_detail_btn:hover {
  background-color: #52d3f3;
  border: none;
  color: #fff;
}

.contact_plan_summery .header_title {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font: normal normal 600 14px/45px Poppins;
  letter-spacing: 0px;
  color: #04A7D0;
  text-transform: uppercase;
}

/* .contact_plan_summery .cntct_title {
  padding: 10px 0px;
} */

.contact_plan_summery .cntct_title .cntct_fields .input-style {
  margin-bottom: 0px;
}

.contact_plan_summery .cntct_title .cntct_fields .input-style label {
  text-align: left;
  margin-bottom: -2px;
  font-weight: 500;
  font: normal normal normal 14px/15px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}

.contact_plan_summery .cntct_title .cntct_fields .input-style input[type=text] {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.input-style input[type=number] {
  border-radius: 4px;
  border: 1px solid #95D6E9;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-style input[type=number] input:focus input:hover {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.contact_plan_summery .cntct_title .cntct_fields .input-style [type=text] {
  width: 100%;
  padding: 10px 20px;
  margin: 8px 0px 0px 0px;
  box-sizing: border-box;
}

.contact_plan_summery .cntct_title .cntct_fields .input-style input:focus {
  background-color: #F3FAFD;
  border: 1px solid #04a7d0 !important;
}


.address_plan_summery .header_title {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font: normal normal 600 14px / 45px Poppins;
  letter-spacing: 0px;
  color: #04A7D0;
  text-transform: uppercase;
  margin-top: 10px;
}

/* .address_plan_summery .adres_title{
    padding: 10px 0px;
} */

.address_plan_summery .adres_title .address_fields .input-style input[type=text] {
  width: 100%;
  padding: 10px 20px;
  margin: 8px 0px 0px 0px;
  box-sizing: border-box;
}

.address_plan_summery .adres_title .address_fields .input-style label {
  margin-bottom: -2px;
  text-align: left;
  font: normal normal normal 14px / 15px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}

.address_plan_summery .adres_title .address_fields .input-style {
  margin-bottom: 10px;
}

.address_plan_summery .adres_title .address_fields .input-style input[type=text] {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
}

.terms-label {
  text-align: left;
  font: normal normal normal 12px/15px Poppins;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
}

.address_plan_summery .adres_title .address_fields .input-style input:focus {
  background-color: #F3FAFD;
  border: 1px solid #04a7d0 !important;
}



.address_plan_summery .adres_title .address_fields .checkout-btn {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 10px 25px;
  width: 177px;
}

.address_plan_summery .adres_title .address_fields .checkout-btn:hover {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 10px 25px;
  width: 177px;
}

.header_style_logo {
  display: flex;
  margin-top: 20px;
}

/* .congratulation-page{margin-top:150px;} */
.congratulation-page .main_title {
  font: normal normal bold 28px/55px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.congratulation-page .sub_title {
  text-align: center;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
}

.congratulation-page .sub_title span {
  text-align: center;
  font: normal normal 600 12px/24px Poppins;
  letter-spacing: 0px;
  color: #04A7D0;
}

.click_congrates {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  padding: 5px 25px;
  margin-bottom: 25px;
  font: normal normal 600 15px/45px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.click_congrates-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.click_congrates span {
  font: normal normal 600 15px/45px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.cm_design_logo li.ht_logo {
  margin-top: 25px;
}

.NavBarNaviation {
  font: normal normal 600 12px / 35px Poppins;
  /* height: 60px;
  width: 100%;
  position: fixed;
  z-index: 999;
  opacity: .9;
  display: flex;
  justify-content: center;
  align-items: center */
}

.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all .3s ease-out;
  padding: 7px 16px;
  box-shadow: 0 10px 30px 0 rgb(138 155 165/15%)
}

.NavBar nav ul {
  list-style: none;
  margin: 0;
  padding: 0
}

.NavBar nav ul li {
  display: inline-block;
  margin: 1.5vw
}

.NavBar a:hover {
  color: red
}

.NavBar nav ul li a {
  text-decoration: none !important;
  color: #000 !important
}

.NavBar a:active {
  color: red !important
}

.MobileNavigation {
  display: none
}

.dislogin-btnV2 {
  background: #04a7d0bd 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  pointer-events: none;
  padding: 7px 1px;
  font: normal normal 600 13px/25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.dislogin-btnV2-active {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  pointer-events: none;
  padding: 7px 42px;
  color: #fff;
  font: normal normal 600 13px/25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.clickV2 {
  background-color: #fff;
  padding: 7px 17px;
  border-radius: 26px;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #20afd4;
  color: #5dc6e1;
}

.otpboxV2 span {
  visibility: hidden;
}

button:focus {
  outline: none !important;
}

.t-c-style {
  margin-left: -24px;
  font-size: 14px;
  list-style-type: decimal;
}

.t-c-style li {
  margin-top: 10px;
}

#t_c_box .modal-content {
  height: 71%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 95%;
}

#t_c_box .modal-content h4 {
  font-size: 20px;
}

.t_c_box_design {
  box-shadow: 0 10px 10px rgb(0 0 0 / 20%);
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 10px;
}

::marker {
  font-weight: 600
}

.mt--t_c {
  margin-top: 100px;
}

#t_c_box .modal-body .close {
  position: absolute;
  top: -7px;
  right: -4px;
  background: #000;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 34px;
  z-index: 999999999;
}

::marker {
  font-weight: 600
}

.ht_city_selct input:checked+label .circle_style {
  background: #DDF2F7 0% 0% no-repeat padding-box;
}

.ht_city_selct input:checked+label .fnt-style {
  font: normal normal 900 11px / 15px Poppins;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 5px;
}

/* .ht_city_selct {
  margin-top:-20px;
} */
.circle_style {
  /* width: 96px;
  height: 116px;
  margin-right: auto;
  margin-left: auto; */
  position: relative;
  border-radius: 10px;
  border: 1px solid #DDDDDD;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.circle_style svg {
  width: 100%;
}

.cm_box_design .input-group {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
}

/* <!-----------end web------------------> */


@media only screen and (max-width : 767px) {

  /* .ht_city_selct input:checked + .label-div label {
    box-shadow:1px 6px 5px 0pxrgb(0 0 0 / 30%);
    border-color: #034f5a;
    z-index: 1;
    background:#00B1CC;
    color:#00B1CC;
  } */
  .ht_city_selct input:checked+label .circle_style {
    background: #DDF2F7 0% 0% no-repeat padding-box;
  }

  .ht_city_selct sinput~.tab {
    border-top: 1px solid #999;
    padding: 12px;
  }

  /* #demo.carousel {margin-top:77px;} */

  #demo .carousel-indicators {
    bottom: -70px;
    list-style: none;
  }

  .stepNavBar {
    max-width: 320px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .stepItem span {
    width: 38px;
    height: 38px;
    font-size: 24px;
    line-height: 38px;
  }

  .stepNavBar ul li::after {
    height: 2px;
    width: 45px;
    left: 80px;
    top: 18px;
  }

  .stepNavBar ul li.active .stepItem::before {
    height: 2px;
    right: 80px;
    top: 18px;
    width: 45px;
  }

  .stepNavBar ul li.active .stepItem::after {
    height: 2px;
    left: 80px;
    top: 18px;
    width: 22px;
  }

  .regBtns {
    text-align: center;
    margin-bottom: 20px;
  }

  .registerBox {
    padding: 5px 0;
  }

  .registerBox h2 {
    font-size: 14px;
  }

  .mobileField input.form-control {
    padding-left: 92px;
  }

  .mobileField .countryCode {
    left: 10px;
    top: 6px;
    font-size: 14px;
    padding-left: 36px;
    width: 70px;
    background-size: 29px auto;
  }

  .mobileField .mobileEdit {
    font-size: 14px;
    right: 10px;
    top: 6px;
  }

  .regBtns {
    text-align: center;
    margin-bottom: 20px;
  }

  .regNoSpam {
    width: 178px;
    background-size: 26px auto;
    padding-left: 32px;
    margin: 0 auto;
  }

  .otpField .form-control {
    max-width: 40px;
    flex: 0 0 40px;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-top: 0;
  }

  /* .absolute-center{
    padding-top: 0px !important;
  } */

  .container .navbar-brand {
    text-align: left;
  }

  .navbar-nav .nav-link {
    font-size: 8px;
    margin-top: -11%;

  }

  /* 24-Aug-2022- Media UI change on new custome page on  */
  .selectCityForm p.cm-title {
    font-size: 12px;
    margin-bottom: 18px;
    margin-top: -12px;
    font-weight: 600;
    color: #000;
    margin-left: 10px;
  }

  .selectCityForm p.default-msg {
    font-size: 13px;
    margin-bottom: 22px;
    font-weight: 400;
  }


  .whiteSection1 {
    padding: 10px;
  }

  .year {
    font-weight: 700;
    font-size: 24px;
    margin-top: 0px;
    color: black;
  }

  .cm_planDetail {
    padding: 6px 6px;
  }

  .pd_10 {
    padding: 2px;
    margin-right: 5px;
  }

  .wd_150 {
    width: 75px;
    height: 90px;
    border-radius: 10px;
  }

  .py-3 {
    padding-top: 10px;
  }

  .planPricenew {
    font-size: 20px;
    line-height: 18px;
    flex: 1;
    color: #1f1f1f;
    text-align: right;
  }

  .planPricenew em {
    top: 0;
    font-weight: 700;
    font-style: normal;
  }

  .planPricenew span em {
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    vertical-align: middle;
    font-weight: 400;
    margin-left: 5px;
  }


  .planPricenew span {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
  }

  .selectcitytitle {
    color: black;
    margin-bottom: 10px;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 600;
    margin-top: -10px;
  }

  .pincodetitle {
    color: black;
    margin-bottom: 10px;
    margin-left: 12px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 4px;
  }

  .cm_planDuration {
    max-width: 138px;
    /* flex: 0 0 110px; */
    font-size: 18px;
    font-weight: 600;
    color: #20afd4;
    margin-top: 10px;
  }

  .banText h1 {
    font-size: 17px;
  }

  .banText ul li {
    margin-bottom: 4px;
    font-size: 10px;
  }

  .cm-flex-type-2 {
    margin-bottom: -3px;
  }

  .ht_banner_title h1 {
    font-family: 'blacker_pro_displayheavy';
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: bolder;
    margin-left: -13px;
    color: #000;
  }

  .ht_banner_title {
    margin-top: 30px;
  }

  .fnt-style-city1 {
    font: normal normal bold 20px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 5px;
  }

  .banner_subtitle {
    font-size: 10px;
    color: #000;
    margin-left: -12px;
  }

  .ht_banner_title ul {
    margin-left: -33px;
  }

  .ht_banner_title ul li {
    font-size: 9px;
    color: #757575;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .ht_banner_img img {
    width: 100%;
    margin-top: 3px;
  }

  /* END */

  /* ------30-Aug start media---- */
  .ht_page {
    border: none;
    padding: 30px 15px 10px;
    border-radius: 10px;
  }

  .ht_tab {
    display: flex;
    margin-right: 7px;
  }

  .text-3xl img {
    margin-top: 6px;
  }

  .title_more {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    color: #0A94A9;
  }

  .title_less {
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    color: #0A94A9;
  }

  .title_more span {
    margin-left: 10px;
  }

  .title_less span {
    margin-left: 10px;
  }

  .ht_publication_tab {
    display: inline-block;
    padding: 0px 7px 25px 0px;
  }
  .ht_publication_selct {
    padding: 10px 10px 0px 10px;
    justify-content: space-around;
  }

  .ht_publication_selct input {
    display: none;
  }

  .ht_publication_selct input~.tab {
    display: none
  }

  .ht_publication_selct #tab1:checked~.tab.content1,
  .ht_publication_selct #tab2:checked~.tab.content2,
  .ht_publication_selct #tab3:checked~.tab.content3,
  .ht_publication_selct #tab4:checked~.tab.content4,
  .ht_publication_selct #tab5:checked~.tab.content5 {
    display: block;
  }

  .ht_publication_selct input+label {
    border: 1px solid #e7e7e7;
    background: #fff;
    border-radius: 10px;
    position: relative;
  }

  .ht_publication_selct input:checked+label {
    background: #DBF0F5 0% 0% no-repeat padding-box;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
  }

  .ht_publication_selct input~.tab {
    border-top: 1px solid #999;
    padding: 12px;
  }

  .pd_x_y {
    padding-right: 6px;
    padding-left: 6px;
  }


  .ht_city_selct input {
    display: none;
  }

  .ht_city_selct input+label {
    display: inline-block
  }

  .ht_city_selct input~.tab {
    display: none
  }

  .ht_city_selct #tab1:checked~.tab.content1,
  .ht_city_selct #tab2:checked~.tab.content2,
  .ht_city_selct #tab3:checked~.tab.content3,
  .ht_city_selct #tab4:checked~.tab.content4,
  .ht_city_selct #tab5:checked~.tab.content5 {
    display: block;
  }

  .ht_city_selct input+label {
    background: #ffffff;
    border-radius: 10px;
    position: relative;
  }

  .ht_city_selct input:checked+label {
    z-index: 1;
  }

  .ht_city_selct {
    justify-content: space-between;
  }

  .circle_style {
    position: relative;
  }

  .circle_style img {
    width: 60px;
  }

  .ht_city_selct sinput~.tab {
    border-top: 1px solid #999;
    padding: 12px;
  }

  .fnt-style {
    font: normal normal 900 11px / 15px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-align: center;
    margin-top: 5px;
  }

  .fnt-style-city {
    font-size: 11px;
    margin-top: 26px;
    /* margin-left: -17px */
  }

  /* ----End 30-Aug--- */


  .cm_design_logo li img {
    width: 90px;
  }

  .cm_design_logo li {
    width: 24%;
  }

  .cm_design_logo li.mint_logo {
    margin-top: 30px;
  }

  .NavBar nav ul {
    display: flex;
    flex-direction: initial;
    position: absolute;
    top: -14px;
    right: 34px;
    width: 100%;
    background: rgb(248 249 250);
  }




  .bottom_contact {
    background: #eee;
    padding: 0px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
  }

  .cm_design_logo li.ht_logo {
    margin-top: 32px;
  }

  .NavBar nav ul {
    display: flex;
    /* flex-direction: column; */
    position: absolute;
    /* top: -14px; */
    right: 34px;
    width: 100%;
    background: rgb(248 249 250);
  }

  /* .cm_box_design p{font-size:15px; font-weight: 600; margin-bottom: 23px;color: #00B1CC;} */

  #demo .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    height: 65%;

  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    /* width: 86%; */
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1pxsolidrgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
  }

  .mt-100 {
    margin-top: 24px;
  }

  .bottom_contact p span {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    color: #000000a3;
    text-align: center;
    background: none;
    padding: 10px;

  }

  .bottom_contact p span:hover {
    font-size: 14px;
    background: none;
    color: #000;

  }

  .plan_header_box .year_title p {
    /* padding: 10px 0 0 8px; */
    font: normal normal normal 14px/20px Poppins;
    letter-spacing: 0px;
    color: #8B8B8B;
  }

  .plan_header_box .plan_total_title p span.plan_month {
    float: right;
    margin-right: 3px;
  }

  .popup_style {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: -1px 1px 10px rgb(0 0 0 / 20%);
    display: flex;
    margin: auto;
    padding: 14px 0px;
  }

  .header_style_logo {
    display: flex;
    margin-top: 20px;
  }

  .click_congrates {
    margin-top: 30px;
    position: absolute;
    top: 0;
  }
  .congratulation-page .click_congrates {
    margin-top: 30px;
    position: relative;
    top: 0;
  }

  .carousel-inner img {
    width: 99%;
    height: 100%;
  }

  ::placeholder {
    font-size: 13px;
  }

  .dislogin-btnV2 {
    background: #04a7d0bd;
  }

  .dislogin-btnV2-active {
    font: normal normal 600 12px/25px Poppins;
  }

  .clickV2 {
    background-color: #fff;
    padding: 7px 17px;
    border-radius: 26px;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #20afd4;
    color: #5dc6e1;
  }

  button:focus {
    outline: none !important;
  }

  .back-homeCheckout {
    left: 202px;
    margin-top: 71px;
  }


  .t-c-style {
    margin-left: -24px;
    font-size: 14px;
    list-style-type: decimal;
  }

  .t-c-style li {
    margin-top: 10px;
  }

  .mt--t_c {
    margin-top: 100px;
  }

  .t_c_box_design {
    box-shadow: 0 10px 10px rgb(0 0 0 / 20%);
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 10px;
  }

  ::marker {
    font-weight: 600
  }

  input {
    outline: none;
  }

  .ht_tab input:checked+.label-div label .fnt-style {
    color: #00B1CC;
  }
}

/* media-end */


/* Ipad media Css -Start */
@media (max-width: 991px) and (min-width: 768px) {

  .main-div {
    max-width: 500px;
    padding: 0 20px;
    margin: 0 auto;
  }

  /* .navbar-brand img {
    width: 50%;
    margin-left: -84px;
  } */

  .selectCityForm {
    max-width: 600px !important;
    margin: 0 auto;
  }

  .ht_tab {
    display: flex;
    margin-right: 7px;
  }

  .ht_city_selct input+label {
    border: 1px solid #e7e7e7;
    background: #fff;
    padding: 14px 13px;
    border-radius: 10px;
    position: relative;
  }

  .ht_publication_tab {
    display: inline-block;
    padding: 0px 10px 15px 0px;
  }

}

/* Ipad media Css -End */

#proceedBtn,
#otpBtn {
  color: #fff;
  background-color: #20afd4;
  border-radius: 20px;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.navbar-brand1 {
  display: inline-block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  margin: auto;
}

.bottom_complaint {
  position: fixed;
  bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  right: 6%;
  z-index: 9999;
}

.bottom_complaint .btn {
  background: #04A7D0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #00000054;
  border-radius: 8px;
  padding: 5px 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  font: normal normal 600 12px/35px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.nws_paper_title{
  display: flex;
}
.nws_paper_title .year_title {
  font: normal normal normal 14px/35px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}

.nws_paper_title .feature {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.nws_paper_price_sec .last_price{
  font: normal normal 900 18px/25px Poppins;
  letter-spacing: 0px;
  color: #04A7D0;
  float: right;
  margin-right: 15px;
}
.nws_paper_price_sec .orgnl_price{
  font: normal normal bold 28px/35px Poppins;
  letter-spacing: 0px;
  color: #04A7D0;
}

@media (max-width: 768px) {

  .back-btn-pincode {
    height: 60px;
  }
  .pricing-features .feature {
    font: normal normal 900 23px / 55px Poppins;
  }

  .whatsapp-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0px 15px 40px;
  }
  #Whatsapp {
    display: flex;
  }

  .whtsapp_style {
    font: normal normal 300 12px / 15px Poppins;
    flex: 1;
    display: flex;
  }

  .cityFl_button {
    position: relative;
  }

  .proceedBtn {
    position: absolute;
    top: -18px;
    left: 0;
  }

  .price-tag .stike {
    width: 92px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .pricing-table {
    padding: 20px 10px;
  }
  .nws_paper_title {
    flex: 1;
  }
  .plan_header_box .nws_paper_title .feature span.p_title{
    font: normal normal 900 18px/30px Poppins;
  }
  .nws_paper_price_sec .last_price {
    font: normal normal 900 12px/30px Poppins;
  }
  .nws_paper_price_sec .orgnl_price{
    font: normal normal 900 18px/30px Poppins;
  }
  .summery_plan_row .px-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }

  .successBtn {
    position: relative;
    padding-bottom: 16%;
  }
  .click_congrates-div {
    position: relative;
    padding-bottom: 16%;
    width: 70%;
  }
}